<template>
    <div>
        <input type="text" class="position-fixed top-0 left-0"
            style="display: none; color: #fff; z-index: 99999; opacity: 0" id="infoInputCopy" />
        <div class="d-flex flex-column" >
            <div class="card d-flex flex-fill flex-grow-1" style="height: 100px">
                <el-tabs v-model="tabName" type="border-card"
                    @tab-click="tabClicked">
                    <el-tab-pane name="deletion">
                        <span slot="label" class=" text-danger">
                            <strong><i class="el-icon-delete mr-1"></i> Eligible for deletion</strong>
                        </span>
                        <div class="d-flex flex-column" 
                            style="height: calc(100dvh - 11rem); border-radius: 10px;">
                            <MachineAbandoned ref="statusFiltered4" :statusFiltered="4"/>                            
                        </div>
                    </el-tab-pane>
                    <el-tab-pane name="w-confirmation">
                        <span slot="label" class=" text-orange">
                            <strong><i class="el-icon-time mr-1"></i> Wait for confirmation</strong>
                        </span>
                        <div class="d-flex flex-column" 
                            style="height: calc(100dvh - 11rem); border-radius: 10px;">
                            <MachineAbandoned ref="statusFiltered2" :statusFiltered="2"/>                            
                        </div>
                    </el-tab-pane>
                    <!-- <el-tab-pane name="confirmed">
                        <span slot="label" class=" text-primary">
                            <strong><i class="el-icon-check mr-1"></i> Confirm to continue</strong>
                        </span>
                        <div class="d-flex flex-column" 
                            style="height: calc(100dvh - 11rem); border-radius: 10px;">
                            <MachineAbandoned ref="statusFiltered3" :statusFiltered="3"/>                            
                        </div>
                    </el-tab-pane> -->
                </el-tabs>
            </div>
            <!-- /.card -->
        </div>
    </div>
</template>

<script>
import lazyLoadComponent from '@/scripts/lazyLoadComponent';
import SkeletonBox from '@/components/SkeletonBox';
export default {
    components: {        
        MachineAbandoned: lazyLoadComponent({
            componentFactory: () => import("@/views/gpuHub/vmMachines/MachineAbandoned.vue"),
            loading: SkeletonBox,
        }),
    },
    data() {
        return {
            tabName: "deletion",
        }
    },
    methods: {
        tabClicked(){
            switch(this.tabName){
                case "deletion":
                    if(this.$refs.statusFiltered4) this.$refs.statusFiltered4.refreshClick();
                    break;
                case "w-confirmation":
                    if(this.$refs.statusFiltered2) this.$refs.statusFiltered2.refreshClick();
                    break;
                case "confirmed":
                    if(this.$refs.statusFiltered3) this.$refs.statusFiltered3.refreshClick();
                    break;
            }
        }
    }
}
</script>

<style scoped>
::v-deep .el-tabs__content {
    padding: 8px !important;
}
</style>